//@author travis

import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { UserprefService } from "./userpref.service";
import { S25WsNode } from "../pojo/S25WsNode";
import { S25ItemI } from "../pojo/S25ItemI";
import { SpaceAssignPermPage } from "./space.service";
import { S25Const } from "../util/s25-const";

const RESOURCE_ARRAYS = { resource: true, category: true, custom_attribute: true, relationship: true, stock: true };

export class ResourceService {
    public static createResource(payload: any, urlAddition: string = "") {
        if (S25Util.isDefined(urlAddition) && urlAddition) urlAddition = "?" + urlAddition;
        return DataAccess.post(
            `/resources.json${urlAddition}`,
            S25Util.getPayload("resources", "resource", "resource_id", "new", "", payload),
        );
    }

    public static putResource(itemId: number, payload: any) {
        return ResourceService.getResourceIncludes(itemId, []).then(function (item) {
            S25Util.coalesceDeep(payload, item);
            delete payload.crc;
            return DataAccess.put(
                "/resource.json?resource_id=" + itemId,
                S25Util.getPayload("resources", "resource", "resource_id", "mod", itemId, payload),
            );
        });
    }

    public static putResourceReplace(itemId: number, payload: any, returnDoc?: boolean) {
        return ResourceService.getResourceIncludes(itemId, []).then(function (item) {
            Object.assign(item, payload);
            delete payload.crc;
            let url = "/resource.json?resource_id=" + itemId;
            url = returnDoc ? url + "&return_doc=T" : url;
            return DataAccess.put(
                DataAccess.injectCaller(url, "ResourceService.putResourceReplace"),
                S25Util.getPayload("resources", "resource", "resource_id", "mod", itemId, item),
            );
        });
    }

    public static async getResourcesName(ids: number[]): Promise<S25ItemI[]> {
        if (!ids.length) return [];
        ids = S25Util.array.unique(ids);
        const objs: S25WsResource[] = await ResourceService.getResourcesMinimal(ids);
        const objIdMap = S25Util.fromEntries(objs.map((obj) => [obj.resource_id, obj]));
        const idIndexMap = S25Util.fromEntries(ids.map((id, index) => [id, index]));
        return (objs ?? [])
            .map((obj) => ({ itemId: obj.resource_id, itemName: obj.resource_name }))
            .concat(ids.filter((id) => !objIdMap[id]).map((id) => ({ itemId: id, itemName: S25Const.private })))
            .sort((a, b) => {
                return idIndexMap[S25Util.toInt(a.itemId)] - idIndexMap[S25Util.toInt(b.itemId)];
            });
    }

    public static getResourcesMinimal(idArray: number[]): Promise<S25WsResource[]> {
        idArray = S25Util.array.forceArray(idArray);
        return DataAccess.get<{ resources: { resource: S25WsResource[] } }>(
            DataAccess.injectCaller(
                "/resources.json?resource_id=" + idArray.join("+") + "&scope=minimal",
                "ResourceService.getResourcesMinimal",
            ),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, RESOURCE_ARRAYS);
            return (data && data.resources && data.resources.resource) || [];
        });
    }

    @Cache({ immutable: true, targetName: "ResourceService" })
    public static getResourcesBySearchQuery(searchQuery: string, includes: string[], dataScope?: string) {
        if (includes && includes.length) {
            dataScope = "extended";
        }
        var url = "/resources.json";
        url += dataScope ? (url.indexOf("?") > -1 ? "&" : "?") + "scope=" + dataScope : "";
        url += includes && includes.length ? (url.indexOf("?") > -1 ? "&" : "?") + "include=" + includes.join("+") : "";
        url += url.indexOf("?") > -1 ? searchQuery : searchQuery.replace("&", "?"); //note: only first & is replaced with ?
        return DataAccess.get(DataAccess.injectCaller(url, "ResourceService.getResourcesBySearchQuery")).then(
            function (data) {
                data = data && S25Util.prettifyJson(data, null, RESOURCE_ARRAYS);
                return data;
            },
        );
    }

    public static getResourceName(id: number): Promise<string> {
        return ResourceService.getResourcesMinimal([id]).then(function (resource) {
            if (!resource?.[0]) return S25Const.private;
            return resource[0].resource_name || "";
        });
    }

    @Timeout
    public static getResourceAssignPerms(queryString: string) {
        return UserprefService.getLoggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) return {};
            const query = queryString
                .replace(/&resources_/g, "&") // E.G. "spaces_query_id" -> "query_id"
                .replace(/&resource_(name|query_id|favorite)/g, "&$1");
            return DataAccess.get(
                DataAccess.injectCaller(
                    "/micro/resource/access/currentUser/list.json?include=assign_policy" + query,
                    "getResourceAssignPerms",
                ),
            ).then(function (data) {
                if (data.content?.data?.items?.length) {
                    return new Map(
                        data.content.data.items.map((p: { id: any; assignPolicy: any[] }) => {
                            return [p.id, p.assignPolicy[0]];
                        }),
                    );
                } else {
                    return {};
                }
            });
        });
    }

    @Timeout
    public static _getResourceAssignPermsPage(url: string) {
        return DataAccess.get(DataAccess.injectCaller(url, "getResourceAssignPermsPage"));
    }

    @Timeout
    public static getResourceAssignPermsPage(
        queryString: string,
        paginateKey: number,
        page: number = 1,
    ): Promise<Partial<SpaceAssignPermPage>> {
        return UserprefService.getLoggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) return {};
            const query = queryString
                .replace(/&resources_/g, "&") // E.G. "spaces_query_id" -> "query_id"
                .replace(/&resource_(name|query_id|favorite)/g, "&$1");
            return ResourceService._getResourceAssignPermsPage(
                `/micro/resource/access/currentUser/list.json?include=assign_policy&paginate=${
                    paginateKey || ""
                }&page=${page}&${query}`,
            ).then(function (data) {
                const response = {
                    paginateKey: data.content?.data?.paginateKey,
                    page: data.content?.data?.pageIndex,
                    totalPages: data.content?.data?.totalPages,
                    totalItems: data.content?.data?.totalItems,
                    currentItemCount: data.content?.data?.currentItemCount,
                    itemsPerPage: data.content?.data?.itemsPerPage,
                    items: new Map(),
                };
                if (data.content?.data?.items?.length) {
                    response.items = new Map(
                        data.content.data.items.map((p: { id: any; assignPolicy: any[] }) => {
                            return [p.id, p.assignPolicy[0]];
                        }),
                    );
                }
                return response;
            });
        });
    }

    @Timeout
    public static getResource(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/resource.json?resource_id=" + id, "ResourceService.getResource"),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, RESOURCE_ARRAYS);
            return data && data.resources && data.resources.resource && data.resources.resource[0];
        });
    }

    @Timeout
    public static deleteResource(id: number) {
        return DataAccess.delete(
            DataAccess.injectCaller("/resource.json?resource_id=" + id, "ResourceService.deleteResource"),
        ).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                var isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "RS_I_DELETED";
                return { error: !isSuccess, success: isSuccess };
            },
            function (error) {
                console.log(error);
                return { error: error, success: false };
            },
        );
    }

    public static getResourcesIncludes(idArr: number[], includes: string[]) {
        idArr = S25Util.array.forceArray(idArr);
        return DataAccess.get(
            DataAccess.injectCaller(
                "/resources.json?resource_id=" + idArr.join("+") + "&scope=extended&includes=" + includes.join("+"),
                "ResourceService.getResourcesIncludes",
            ),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, RESOURCE_ARRAYS);
            return data && data.resources && data.resources.resource;
        });
    }

    public static getResourceIncludes(itemId: number, includes: string[]) {
        return ResourceService.getResourcesIncludes([itemId], includes).then(function (resources) {
            return resources && resources.length && resources[0];
        });
    }

    public static getResourceDatesAvailability(
        resources: S25ItemI[],
        datesArr: { startDt: Date; endDt: Date }[],
        contextEventId: number,
        contextProfileId: number,
    ) {
        //filter out any duplicate resource ids - res_avail.json will choke
        resources = resources.filter((el, index) => {
            return S25Util.array.findByProp(resources, "itemId", el.itemId) === index;
        });

        var json = {
            resource_availability: {
                event: {
                    event_id: contextEventId || "",
                    profile: {
                        profile_id: contextProfileId || "",
                    },
                },
                requirements: {
                    resource: resources
                        .filter(function (obj) {
                            return !!obj.itemId;
                        })
                        .map(function (obj) {
                            return { resource_id: obj.itemId, quantity: obj.quantity };
                        }),
                },
                dates: datesArr.map(function (obj) {
                    return {
                        start_dt: S25Util.date.toS25ISODateTimeStr(obj.startDt),
                        end_dt: S25Util.date.toS25ISODateTimeStr(obj.endDt),
                    };
                }),
            },
        };

        return DataAccess.post(
            DataAccess.injectCaller("/res_avail.json", "ResourceService.getResourceDatesAvailability"),
            json,
        ).then(function (data) {
            data =
                data &&
                S25Util.prettifyJson(data, null, {
                    resource: true,
                    dates: true,
                    conflict: true,
                    also_assign: true,
                    also_dates: true,
                    also_conflict: true,
                });
            return data;
        });
    }

    public static nodeToObj(node: any) {
        let obj: any = {};
        if (node && node.stock) {
            //{startDt, endDt, itemId, count}
            node.stock = S25Util.array.forceArray(node.stock);
            obj.stock = [];
            for (let el of node.stock) {
                let level = {
                    count: { data: el.stock_level },
                    startDt: {
                        data: el.stock_start_dt,
                    },
                    endDt: { data: el.stock_end_dt },
                    itemId: el.version_nbr,
                    status: el.status,
                };
                obj.stock.push(level);
            }
        } else {
            console.log("no conversion defined yet", node);
        }

        return obj;
    }

    public static objToNode(obj: any) {
        let node: any = {};
        if (obj && obj.stock) {
            node.stock = [];
            obj.stock = S25Util.array.forceArray(obj.stock);
            for (let el of obj.stock) {
                let level = {
                    stock_level: el.count && el.count.data,
                    stock_start_dt: el.startDt && el.startDt.data,
                    stock_end_dt: el.endDt && el.endDt.data,
                    version_nbr: el.itemId,
                    status: el.status,
                };
                node.stock.push(level);
            }
        } else {
            console.log("no conversion defined yet", node);
        }

        return node;
    }

    // update comments / default set-up  instructions
    public static updateText(ids: number[], type: string, text: string) {
        return DataAccess.put(DataAccess.injectCaller("/resource/text.json", "ResourceService.updateText"), {
            root: {
                type: type,
                text_comment: text,
                resources: ids.map(function (id) {
                    return { resource_id: id };
                }),
            },
        });
    }

    public static updateCategories(ids: number[], addIds: [], removeIds: []) {
        return DataAccess.put(
            DataAccess.injectCaller("/resource/categories.json", "ResourceService.updateCategories"),
            {
                root: {
                    cats_add: addIds.map(function (a: any) {
                        return { cat_id: a.itemId };
                    }),
                    cats_remove: removeIds.map(function (a: any) {
                        return { cat_id: a.itemId };
                    }),
                    resources: ids.map(function (id) {
                        return { resource_id: id };
                    }),
                },
            },
        );
    }

    public static updateStock(ids: number[], stockLevel: number, startDate: any, endDate: any) {
        return DataAccess.put(DataAccess.injectCaller("/resource/stock.json", "ResourceService.updateStock"), {
            root: {
                resources: ids.map(function (id) {
                    return { resource_id: id, stock_level: stockLevel, start_dt: startDate, end_dt: endDate };
                }),
            },
        });
    }

    public static copyResource = function (itemId: number, newItem: any, fields?: any, securityParams?: string) {
        return ResourceService.getResourceIncludes(itemId, [
            "stock",
            "categories",
            "attributes",
            "relationships",
            "text",
        ]).then((orig) => {
            let newResource = S25Util.deepCopy(orig);
            newResource.resource_id = "";
            newResource.resource_name = newItem.itemName || "";
            S25Util.replaceDeep(newResource, { status: "new" });

            delete newResource.crc;
            return ResourceService.createResource(newResource, securityParams).then((resp) => {
                return resp.results.info.id;
            });
        });
    };

    @Timeout
    public static deleteResources(ids: any) {
        let payload: any = { map: { resource_id: ids } };
        return DataAccess.delete(
            DataAccess.injectCaller("/resources.json", "ResourceService.deleteResources"),
            payload,
        ).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                let isSuccess = resp?.results?.info?.msg_id === "RS_I_DELETED";
                let noPerms =
                    resp &&
                    resp.results &&
                    resp.results.noPerm &&
                    resp.results.noPerm.item &&
                    S25Util.array.forceArray(resp.results.noPerm.item).map((item: any) => {
                        return {
                            itemName: item.object_name,
                            itemId: item.object_id,
                            itemTypeId: item.object_type,
                        };
                    });
                return { error: !isSuccess, success: isSuccess, noPerms: { items: noPerms } };
            },
            function (error) {
                S25Util.showError(error);
                return { error: error, success: false };
            },
        );
    }
}

export interface S25WsResource extends S25WsNode {
    edit_perm?: "T" | "F";
    assign_perm?: "T" | "F";
    last_mod_dt?: string;
    stock_level?: number | string;
    schedule_perm?: "T" | "F";
    last_mod_user?: string;
    resource_id?: number;
    resource_name?: string;
    favorite?: "T" | "F";
}
