import { DataAccess } from "../dataaccess/data.access";
import { Cache } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { S25Util } from "../util/s25-util";
import { Event } from "../pojo/Event";
import { Contact } from "../pojo/Contact";
import { TelemetryService } from "./telemetry.service";

export type GetRegistrationResponse = {
    id: number;
    updated: string;
    expandedInfo: {
        contacts: Contact.Data[];
    };
    data: {
        items: {
            dates: {
                startDate: string;
                endDate: string;
            };
            etag: string;
            eventLocator: string;
            eventName: string;
            eventTitle: string;
            id: number;
            kind: string;
            priority: number;
            updated: string;
            profiles: {
                comments?: string;
                expectedCount: number;
                name: string;
                profileId: number;
                registeredCount: number;
                occurrenceDefn: {
                    adHocDates: string[];
                    initEndDt: string;
                    initStartDt: string;
                    recTypeId: number;
                };
                reservations: RegistrationReservation[];
            }[];
            registration: {
                contactId: number;
                reservations: {
                    rsrvId: number;
                    status: Event.Reservation.StateId;
                }[];
            }[];
        }[];
    };
};

export type RegistrationReservation = {
    availableAttendance: number;
    evEndDt: string;
    evStartDt: string;
    rsrvEndDt: string;
    rsrvId: number;
    rsrvStartDt: string;
    state: Event.Reservation.StateId;
};

type RegistrationWithOccurrences = RegistrationReservation & {
    date: string;
    times: string;
    dateTime: string;
    stateName: string;
    registrants: Record<number, { val: number; txt: string }>;
};

export class RegistrationService {
    public static registrationStatus: { val: number; txt: string }[] = [
        { val: 1, txt: "Expressed Interest" },
        { val: 2, txt: "Unconfirmed" },
        { val: 3, txt: "Wait List" },
        { val: 4, txt: "Confirmed" },
        { val: 5, txt: "No Show" },
        { val: 6, txt: "Cancelled" },
    ];

    @Timeout
    @Cache({ immutable: true, targetName: "RegistrationService" })
    public static putRegistration(eventId: number, status: number, contactId: number, occurrences?: any[]) {
        //occurences can be numbers OR {status: "", rsrvId: ""}
        let payload: any = {
            content: {
                data: [],
            },
        };
        if (occurrences && occurrences.length && occurrences.length > 0) {
            if (occurrences[0].status || occurrences[0].rsrvId) {
                for (let occ of occurrences) {
                    payload.content.data.push({
                        contactId: contactId,
                        status: occ.status || status,
                        rsrvIdList: [occ.rsrvId || occ],
                    });
                }
            } else {
                payload = {
                    content: {
                        data: [{ contactId: contactId, status: status, rsrvIdList: occurrences }],
                    },
                };
            }
        } else {
            payload = {
                content: {
                    data: [{ contactId: contactId, status: status, occurrences: "all" }],
                },
            };
        }
        return DataAccess.put(
            DataAccess.injectCaller(
                "/micro/event/" + eventId + "/registration.json?",
                "RegistrationService.putRegistration",
            ),
            payload,
        ).then(function (data) {
            return data && data.content;
        });
    }

    @Timeout
    public static addRegistrantRow(eventId: number, status: any, contact: any, occurrences?: number[]) {
        TelemetryService.sendWithSub("EventDetails", "Registration", "AddReg");
        return RegistrationService.putRegistration(eventId, status.val, contact.itemId, occurrences).then(
            function (data) {
                let respData = {
                    registration: (data && data.data && data.data.items && data.data.items[0].registration) || [],
                };
                //extend the response data to fill columns
                respData.registration.contactId = contact.itemId;
                respData.registration.displayName = contact.itemName;
                respData.registration.email = contact.itemDesc;
                respData.registration.singleStatus = status;
                return respData.registration;
            },
        );
    }

    @Timeout
    @Cache({ immutable: true, targetName: "RegistrationService", expireTimeMs: 1000 })
    public static getRegistration(eventId: number, occurrences?: number[], contactIds?: number[]) {
        let url = "/micro/event/" + eventId + "/registration.json?";
        if (occurrences && occurrences.length) {
            let rsrvIdList = occurrences.join("+");
            url = url + "rsrvIdList=" + rsrvIdList + "&";
        }
        if (contactIds && contactIds.length) {
            let contactIdsList = contactIds.join("+");
            url = url + "contactIds=" + contactIdsList + "&";
        }
        url = url + "include=reservations+contacts+registration_avail&expand=T";
        return DataAccess.get(DataAccess.injectCaller(url, "RegistrationService.getRegistration")).then(
            function (data) {
                const content: GetRegistrationResponse = data && data.content;
                return content;
            },
        );
    }

    @Timeout
    public static getRegistrants(eventId: number, occurrences?: number[]) {
        return RegistrationService.getRegistration(eventId, occurrences).then(function (data: any) {
            let respData = {
                profiles: (data && data.data.items && data.data.items[0].profiles) || [],
                registration: (data && data.data && data.data.items && data.data.items[0].registration) || [],
                contacts: (data && data.expandedInfo && data.expandedInfo.contacts) || [],
            };
            if (respData.contacts.length < respData.registration.length) {
                alert("There was an issue getting contact data, please refresh the page.");
            } else if (respData.contacts.length === respData.registration.length) {
                respData.contacts.sort(S25Util.shallowSort("contactId", true));
                respData.registration.sort(S25Util.shallowSort("contactId", true));
                //get full contact details on registration
                for (let i = 0; i < respData.registration.length; i++) {
                    respData.registration[i] = { ...respData.registration[i], ...respData.contacts[i] };
                }
            } else {
                //more contacts were returned in the reservations - this shouldn't happen but I don't trust it
                respData.contacts.sort(S25Util.shallowSort("contactId", true));
                respData.registration.sort(S25Util.shallowSort("contactId", true));
                //get full contact details on registration
                let j = 0;
                for (let i = 0; i < respData.registration.length; i++) {
                    while (
                        j < respData.contacts.length &&
                        respData.registration[i].contactId !== respData.contacts[j].contactId
                    ) {
                        j++;
                    }
                    respData.registration[i] = { ...respData.registration[i], ...respData.contacts[j] };
                    if (j < respData.contacts.length - 1) {
                        j++;
                    } else {
                        j = i + 1;
                    }
                }
                //S25Util.merge(respData.registration, respData.contacts); //get full contact details on registration
            }
            let selected = occurrences && occurrences.length;
            respData.registration.resCount = 0;
            //event occurrence count
            for (let p of respData.profiles) {
                if (p.reservations && p.reservations.length) {
                    respData.registration.resCount += p.reservations.length;
                }
            }
            for (let c of respData.registration) {
                let counts = RegistrationService.registrationStatus.map((r) => {
                    return { ...r, count: 0 };
                });

                if (c.reservations && c.reservations.length) {
                    if (selected > 0) {
                        c.includedReservations = [];
                        for (let r of c.reservations) {
                            let rsrvId: number = r.rsrvId;
                            if (occurrences.indexOf(rsrvId) > -1) {
                                c.includedReservations.push(r);
                                var num = r.status;
                                if (num >= 1 && num <= 6) {
                                    counts[num - 1].count++;
                                }
                            }
                        }
                        c.regCounts = counts.filter((s) => s.count > 0);
                        //if the contact is registered for and has only one status for all selected occurrences
                        if (c.regCounts.length === 1 && c.regCounts[0].count === selected) {
                            c.singleStatus = c.regCounts[0];
                        } else {
                            c.singleStatus = null;
                        }
                    } else {
                        //all occurrences
                        c.includedReservations = c.reservations;
                        for (let r of c.reservations) {
                            var num = r.status;
                            if (num >= 1 && num <= 6) {
                                counts[num - 1].count++;
                            }
                        }
                        c.regCounts = counts.filter((s) => s.count > 0);
                        //if the contact is registered for and has only one status for all occurrences in event
                        if (c.regCounts.length === 1 && c.regCounts[0].count === respData.registration.resCount) {
                            c.singleStatus = c.regCounts[0];
                        } else {
                            c.singleStatus = null;
                        }
                    }
                }
                c.displayName =
                    c.familyName && c.firstName ? c.familyName + ", " + c.firstName : c.familyName || c.firstName || "";
            }

            return respData.registration;
        });
    }

    @Timeout
    public static getOccurrences(eventId: number, contactIds?: number[]) {
        // return RegistrationService.getRegistration(eventId).then(function (data) {
        return RegistrationService.getRegistration(eventId, null, contactIds).then((data) => {
            let profiles = (data && data.data.items && data.data.items[0].profiles) || [];
            let occurrences: any = [];
            let occStatuses: any = {
                1: "Active",
                99: "Cancelled",
                2: "Exception",
                3: "Warning",
                4: "Active",
            };
            for (let p of profiles) {
                if (p.reservations && p.reservations.length) {
                    occurrences = occurrences.concat(p.reservations);
                }
            }
            console.log(occurrences);
            for (let o of occurrences) {
                if (o.rsrvEndDt && o.rsrvStartDt) {
                    let stDate = new Date(o.rsrvStartDt);
                    let endDt = new Date(o.rsrvEndDt);
                    o.date = stDate.toDateString();
                    o.times = S25Util.date.toS25ISOTimeStr(stDate) + " - " + S25Util.date.toS25ISOTimeStr(endDt);
                    o.dateTime = o.date + " " + o.times;
                } else {
                    o.date = "Unspecified";
                    o.times = "Unspecified";
                }
                o.stateName = o.state ? occStatuses[o.state] : "Unknown";
            }
            return occurrences;
        });
    }

    @Timeout
    public static getRegistrationWithOccurrences(eventId: number, contactIds?: number[]) {
        return RegistrationService.getRegistration(eventId, null, contactIds).then((data) => {
            let profiles = (data && data.data.items && data.data.items[0].profiles) || [];
            let registration = (data && data.data.items && data.data.items[0].registration) || [];

            let occurrences: RegistrationWithOccurrences[] = [];

            let occStatuses = {
                1: "Active",
                99: "Cancelled",
                2: "Exception",
                3: "Warning",
                4: "Active",
            } as const;
            for (let p of profiles) {
                if (p.reservations && p.reservations.length) {
                    occurrences = occurrences.concat(p.reservations as RegistrationWithOccurrences[]);
                }
            }
            for (let o of occurrences) {
                if (o.rsrvEndDt && o.rsrvStartDt) {
                    let stDate = new Date(o.rsrvStartDt);
                    let endDt = new Date(o.rsrvEndDt);
                    o.date = stDate.toDateString();
                    o.times = S25Util.date.toS25ISOTimeStr(stDate) + " - " + S25Util.date.toS25ISOTimeStr(endDt);
                    o.dateTime = o.date + " " + o.times;
                } else {
                    o.date = "Unspecified";
                    o.times = "Unspecified";
                }
                o.stateName = o.state ? occStatuses[o.state] : "Unknown";
                o.registrants = {};

                for (let r of registration) {
                    let res = S25Util.array.getByProp(r.reservations, "rsrvId", o.rsrvId);
                    res &&
                        (o.registrants[r.contactId] = S25Util.array.getByProp(
                            RegistrationService.registrationStatus,
                            "val",
                            res.status,
                        ));
                }
            }

            return {
                occurrences: occurrences,
                registrants: registration,
            };
        });
    }

    @Timeout
    public static getReservations(eventId: number, occurrences?: number[]) {
        return RegistrationService.getRegistration(eventId, occurrences).then(function (data) {
            return (data && data.data && data.data.items && data.data.items[0].profiles) || [];
        });
    }

    @Timeout
    public static deleteRegistration(eventId: number, contactId: number, occurrences?: number[]) {
        let payload = {};
        if (occurrences && occurrences.length && occurrences.length > 0) {
            let occObjArr: any[] = [];
            occurrences.forEach((occ) => occObjArr.push({ rsrvId: occ }));
            //the service isn't being consistent in requiring rsrvID: or just the list of ids, trying the list again
            //payload = {content: {data: [{"contactId": contactId, "rsrvIdList": occObjArr}]}};
            payload = { content: { data: [{ contactId: contactId, rsrvIdList: occurrences }] } };
        } else {
            payload = { content: { data: [{ contactId: contactId }] } };
        }
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/micro/event/" + eventId + "/registration.json",
                "RegistrationService.deleteRegistration",
            ),
            payload,
        );
    }
}
